import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
import { useApi } from '../../api/ApiProvider';

interface UseEnchancedApiProps {
  token: string;

  onRefreshToken(api: AxiosInstance): Promise<void>;
}

export const useEnchancedApi = ({
  onRefreshToken,
  token,
}: UseEnchancedApiProps) => {
  const api = useApi();

  useEffect(() => {
    const onRequest = async (config: AxiosRequestConfig) => {
      if (token) {
        config.headers = {
          Authorization: config.headers?.['Authorization'] || `Bearer ${token}`,
        };
      }
      return config;
    };

    // Response interceptor for API calls
    const onResponse = async (error: AxiosError) => {
      const originalRequest = error.config;
      const tokenRefreshing = error?.config?.options?.tokenRefreshing;

      if (tokenRefreshing !== undefined || tokenRefreshing === false) {
        return Promise.reject(error);
      }

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          await onRefreshToken?.(api);
          return api(originalRequest);
        } catch (e) {}
      }

      return Promise.reject(error);
    };

    const requestInterceptor = api.interceptors.request.use(
      onRequest,
      (error) => Promise.reject(error),
    );

    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      onResponse,
    );

    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [api, token, onRefreshToken]);

  return api;
};
