import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from '@dpd-ui/core';
import { FC, useEffect, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { useApi } from '../../api/ApiProvider';
import { useMonitorTableDataQuery } from '../applicationMonitorPage/useMonitorTableDataQuery';
import { useConfigurationQuery } from './useConfigurationQuery';

type ConfigurationOverviewProps = {
  onStatusClick: (args: {
    appId: string;
    incidentId: string | undefined | null;
  }) => void;
};

export const ConfigurationOverview: FC<ConfigurationOverviewProps> = (
  props,
) => {
  const { onStatusClick } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const api = useApi();

  const parsed = Object.fromEntries(searchParams);

  const incidentId = searchParams.get('incidentId');
  const appIdFromSearchParams = searchParams.get('appId');

  const [isAppInputInvalid, setIsAppInputInvalid] = useState(false);

  const isNewIncident = incidentId === 'null' || incidentId === null;

  const [startDateTimeValue, setStartDateTimeValue] = useState('');
  const [endDateTimeValue, setEndDateTimeValue] = useState('');
  const [downtimeValue, setDowntimeValue] = useState(0);
  const [countDowntimeValue, setCountDowntimeValue] = useState(true);
  const [summaryInputValue, setSummaryInputValue] = useState('');
  const [rootCauseInputValue, setRootCauseInputValue] = useState('');
  const [remediationInputValue, setRemediationInputValue] = useState('');
  const [impactInputValue, setImpactInputValue] = useState('');
  const [newsletterInputValue, setNewsletterInputValue] = useState(false);
  const [jiraIssueURLInputValue, setJiraIssueURLInputValue] = useState('');
  const [incidentActiveValue, setIncidentActiveValue] = useState(false);

  const params = useParams<{
    appId: string;
    incidentId: string;
  }>();
  const { appId: applicationId } = params;

  const applicationsQuery = useMonitorTableDataQuery({
    viewType: 'last-thirty-days',
  });
  const configurationQuery = useConfigurationQuery({
    incidentId,
  });

  const isEditionView = searchParams.get('incidentId') !== 'null';
  const isCreateIncidentView = searchParams.get('incidentId') == null;

  useEffect(() => {
    if (
      configurationQuery.isSuccess &&
      configurationQuery.data &&
      isEditionView
    ) {
      setDowntimeValue(configurationQuery.data.totalDowntime);
      setSummaryInputValue(configurationQuery.data.summary);
      setRootCauseInputValue(configurationQuery.data.rootCause);
      setRemediationInputValue(configurationQuery.data.remediation);
      setImpactInputValue(configurationQuery.data.impact);
      setJiraIssueURLInputValue(configurationQuery.data.jiraIssueURL);
      setCountDowntimeValue(configurationQuery.data.countDowntime);
      setNewsletterInputValue(configurationQuery.data.newsletterSent);
      setIncidentActiveValue(configurationQuery.data.active);
    }
  }, [configurationQuery.data, configurationQuery.isSuccess, isEditionView]);

  useEffect(() => {
    if (
      configurationQuery.isSuccess &&
      configurationQuery.data &&
      isCreateIncidentView
    ) {
      setDowntimeValue(0);
      setSummaryInputValue('');
      setRootCauseInputValue('');
      setRemediationInputValue('');
      setImpactInputValue('');
      setJiraIssueURLInputValue('');
    }
  }, [
    configurationQuery.data,
    configurationQuery.isSuccess,
    isCreateIncidentView,
    isEditionView,
  ]);

  if (applicationsQuery.isError || configurationQuery.isError) {
    return (
      <Center height="full" width="full">
        <Alert
          status="error"
          as={Stack}
          width="full"
          maxWidth="400px"
          borderRadius="lg"
          border="1px solid"
          borderColor="red.300"
        >
          <VStack>
            <AlertIcon />
            <Text>
              Error:
              {(applicationsQuery.error as Error).message ??
                'Something went wrong'}
            </Text>
          </VStack>
          <Button onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
        </Alert>
      </Center>
    );
  }

  if (applicationsQuery.isLoading || configurationQuery.isLoading) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  const applications = applicationsQuery.data?.applications!;
  const incidentDetails = configurationQuery.data!;

  const mergeSearchParams = (obj: typeof parsed) => {
    setSearchParams({
      ...parsed,
      ...obj,
    });
  };

  const renderActiveIncidentCheckbox = () => {
    return (
      <Checkbox
        borderColor="#808285"
        size="lg"
        isChecked={incidentActiveValue}
        onChange={({ currentTarget: { checked } }) => {
          setIncidentActiveValue(checked);
          if (checked) {
            setCountDowntimeValue(true);
          }
        }}
      >
        <Text fontSize="sm">Active incident</Text>
      </Checkbox>
    );
  };

  const renderCountDowntimeCheckbox = () => {
    return (
      <Checkbox
        borderColor="#808285"
        size="lg"
        isChecked={countDowntimeValue}
        isDisabled={incidentActiveValue}
        onChange={({ currentTarget: { checked } }) =>
          setCountDowntimeValue(checked)
        }
      >
        <Text fontSize="sm">Count as downtime</Text>
      </Checkbox>
    );
  };

  const renderApplicationsDropDown = () => {
    return (
      <FormControl flex={1} isRequired isInvalid={isAppInputInvalid}>
        <FormLabel
          as="legend"
          fontSize="s"
          color="#414042"
          fontWeight="medium"
          sx={{
            '.chakra-form__required-indicator': {
              color: 'black',
            },
            '&[data-invalid] .chakra-form__required-indicator': {
              color: 'red',
            },
          }}
        >
          Application
        </FormLabel>
        {isAppInputInvalid ? (
          <FormErrorMessage>Please, choose some application</FormErrorMessage>
        ) : null}
        <Select
          borderRadius="none"
          border="1px solid"
          borderColor="#808285"
          size="lg"
          value={appIdFromSearchParams ?? applicationId}
          fontSize="sm"
          onChange={({ currentTarget: { value } }) => {
            onStatusClick({ appId: value, incidentId: null });
            setIsAppInputInvalid(!value ? true : false);
          }}
          onBlur={({ currentTarget: { value } }) => {
            setIsAppInputInvalid(!value ? true : false);
          }}
        >
          <option value="">Select application</option>
          {Object.entries(applications)
            .sort(([, { name: a }], [, { name: b }]) => {
              if (a.toLowerCase() < b.toLowerCase()) return -1;
              if (a.toLowerCase() > b.toLowerCase()) return 1;
              return 0;
            })
            .map(([applicationId, { name }]) => {
              return (
                <option key={applicationId} value={applicationId}>
                  {name}
                </option>
              );
            })}
        </Select>
      </FormControl>
    );
  };

  const renderCustomStartDateTimeInput = (label: string) => {
    const defaultDate = new Date().toISOString().slice(0, 16);
    const isInvalidWhenUpdate =
      incidentId !== null && incidentDetails.startTime === '';
    const isInvalidWhenCreate =
      incidentId === 'null' && startDateTimeValue === '';
    const isInvalid = isInvalidWhenUpdate || isInvalidWhenCreate;
    return (
      <FormControl flex={1} isInvalid={isInvalid}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          {label}
        </FormLabel>
        {isInvalid ? (
          <FormErrorMessage>Please, choose the date</FormErrorMessage>
        ) : null}

        <Input
          key={defaultDate}
          type={'datetime-local'}
          borderRadius="none"
          border="1px solid"
          borderColor="#808285"
          size="lg"
          fontSize="0.875rem"
          defaultValue={
            incidentId === 'null'
              ? startDateTimeValue
              : incidentDetails.startTime
          }
          onChange={({ currentTarget: { value } }) =>
            setStartDateTimeValue(value)
          }
        />
      </FormControl>
    );
  };

  const renderCustomEndDateTimeInput = (label: string) => {
    const defaultDate = new Date().toISOString().slice(0, 16);

    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          {label}
        </FormLabel>

        <Input
          key={defaultDate}
          type={'datetime-local'}
          borderRadius="none"
          border="1px solid"
          borderColor="#808285"
          size="lg"
          fontSize="0.875rem"
          defaultValue={
            !incidentId || incidentId === 'null' || !incidentDetails.endTime
              ? endDateTimeValue
              : incidentDetails.endTime.slice(0, 16)
          }
          onChange={({ currentTarget: { value } }) => {
            setEndDateTimeValue(value);
          }}
        />
      </FormControl>
    );
  };

  const renderCustomDowntimeInput = () => {
    const getIncidentTotalDowntime = isNewIncident
      ? 0
      : incidentDetails.totalDowntime;
    const sec = getIncidentTotalDowntime / 1000;
    const days = Math.floor(sec / 60 / 60 / 24);
    const hours = Math.floor(sec / 60 / 60) % 24;
    const minutes = Math.floor(sec / 60) % 60;
    const seconds = Math.floor(sec) % 60;

    const downtime = `${days}d ${hours}h ${minutes}m ${seconds}s`;

    const convertStringValueToMs = (value: string) => {
      const regexValue = value?.match(
        /((?<days>\d+)d)?\s*((?<hours>\d+)h)?\s*((?<minutes>\d+)m)?\s*((?<seconds>\d+)s)?/,
      )?.groups;
      const days = regexValue?.days ?? '0';
      const hours = regexValue?.hours ?? '0';
      const minutes = regexValue?.minutes ?? '0';
      const seconds = regexValue?.seconds ?? '0';

      const daysToMilliseconds = Number(days) * 24 * 60 * 60 * 1000;
      const hoursToMilliseconds = Number(hours) * 60 * 60 * 1000;
      const minutesToMilliseconds = Number(minutes) * 60 * 1000;
      const secondsToMilliseconds = Number(seconds) * 1000;

      const stringValueConvertedToMs =
        daysToMilliseconds +
        hoursToMilliseconds +
        minutesToMilliseconds +
        secondsToMilliseconds;

      setDowntimeValue(stringValueConvertedToMs);
    };

    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          Downtime
        </FormLabel>
        <Flex gap={4}>
          <Input
            borderRadius="none"
            border="1px solid"
            borderColor="#808285"
            size="lg"
            fontSize="0.875rem"
            maxWidth="50%"
            placeholder={downtime}
            defaultValue={downtime}
            onInput={({ currentTarget: { value } }) =>
              convertStringValueToMs(value)
            }
          />
        </Flex>
      </FormControl>
    );
  };

  const renderNewsletterInput = () => {
    return (
      <FormControl flex={1}>
        <Flex gap={4}>
          <Checkbox
            borderColor="#808285"
            size="lg"
            isChecked={newsletterInputValue}
            onChange={({ currentTarget: { checked } }) =>
              setNewsletterInputValue(checked)
            }
          >
            <Text fontSize="sm">Newsletter sent</Text>
          </Checkbox>
        </Flex>
      </FormControl>
    );
  };

  const renderSummaryTextArea = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          Summary
        </FormLabel>

        <Textarea
          borderRadius="none"
          border="1px solid"
          borderColor="#808285"
          rows={8}
          resize="none"
          placeholder="Summary"
          defaultValue={
            searchParams.get('incidentId') === null ||
            searchParams.get('incidentId') === 'null'
              ? ''
              : incidentDetails.summary
          }
          onChange={({ currentTarget: { value } }) => {
            setSummaryInputValue(value);
          }}
        />
      </FormControl>
    );
  };

  const renderRootCauseTextArea = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          Root cause
        </FormLabel>

        <Textarea
          borderRadius="none"
          border="1px solid"
          borderColor="#808285"
          rows={8}
          resize="none"
          placeholder="Root cause"
          defaultValue={
            searchParams.get('incidentId') === null ||
            searchParams.get('incidentId') === 'null'
              ? ''
              : incidentDetails.rootCause
          }
          onChange={({ currentTarget: { value } }) =>
            setRootCauseInputValue(value)
          }
        />
      </FormControl>
    );
  };

  const renderRemediationTextArea = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          Remediation
        </FormLabel>

        <Textarea
          borderRadius="none"
          borderColor="#808285"
          border="1px solid"
          rows={8}
          resize="none"
          placeholder="Remediation"
          defaultValue={
            searchParams.get('incidentId') === null ||
            searchParams.get('incidentId') === 'null'
              ? ''
              : incidentDetails.remediation
          }
          onChange={({ currentTarget: { value } }) => {
            setRemediationInputValue(value);
          }}
        />
      </FormControl>
    );
  };

  const renderImpactTextArea = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          Impact
        </FormLabel>

        <Textarea
          borderRadius="none"
          borderColor="#808285"
          border="1px solid"
          rows={8}
          resize="none"
          placeholder="Impact"
          defaultValue={
            searchParams.get('incidentId') === null ||
            searchParams.get('incidentId') === 'null'
              ? ''
              : incidentDetails.impact
          }
          onChange={({ currentTarget: { value } }) => {
            setImpactInputValue(value);
          }}
        />
      </FormControl>
    );
  };

  const renderJiraLinkInput = () => {
    return (
      <FormControl flex={1}>
        <FormLabel as="legend" fontSize="s" color="#414042" fontWeight="medium">
          Jira issue link
        </FormLabel>
        <Input
          borderRadius="none"
          border="1px solid"
          borderColor="#808285"
          placeholder="Jira issue"
          defaultValue={
            searchParams.get('incidentId') === null ||
            searchParams.get('incidentId') === 'null'
              ? ''
              : incidentDetails.jiraIssueURL
          }
          onChange={({ currentTarget: { value } }) => {
            setJiraIssueURLInputValue(value);
          }}
        />
      </FormControl>
    );
  };

  const createFetchBodyOptions = () => {
    const startTimeValue = () => {
      const fetchedValue = incidentDetails.startTime;
      const noValuePostByUser = startDateTimeValue === '';

      if (searchParams.get('incidentId') !== 'null' && noValuePostByUser) {
        return fetchedValue;
      }
      return startDateTimeValue;
    };

    const endTimeValue = () => {
      const fetchedValue = incidentDetails.endTime;
      const noValuePostByUser = endDateTimeValue === '';

      if (searchParams.get('incidentId') !== 'null' && noValuePostByUser) {
        return fetchedValue;
      }
      return endDateTimeValue === '' ? null : endDateTimeValue;
    };

    const optionsObj = {
      id: isNewIncident ? null : Number(incidentId),
      applicationId: Number(appIdFromSearchParams),
      startTime: startTimeValue(),
      endTime: incidentActiveValue ? null : endTimeValue(),
      countDowntime: incidentActiveValue ? true : countDowntimeValue,
      totalDowntime: incidentActiveValue
        ? null
        : downtimeValue === 0
          ? null
          : downtimeValue,
      summary: summaryInputValue === '' ? null : summaryInputValue,
      rootCause: rootCauseInputValue === '' ? null : rootCauseInputValue,
      remediation: remediationInputValue === '' ? null : remediationInputValue,
      impact: impactInputValue === '' ? null : impactInputValue,
      newsletterSent: newsletterInputValue,
      jiraIssueUrl:
        jiraIssueURLInputValue === '' ? null : jiraIssueURLInputValue,
      active: incidentActiveValue,
    };
    return optionsObj;
  };

  const updateIncidentData = () => {
    if (appIdFromSearchParams === null || appIdFromSearchParams === 'null') {
      setIsAppInputInvalid(true);
      return;
    }
    createFetchBodyOptions();

    if (isAppInputInvalid) return;

    if (searchParams.get('incidentId') === 'null' && startDateTimeValue === '')
      return;

    api.post(`/incident/upsert`, createFetchBodyOptions());
  };

  return (
    <Stack flex={1} padding={5} spacing={6} width={0} overflowY="auto">
      <Heading
        fontWeight="light"
        color="rgba(65, 64, 66, 1)"
        fontSize="1.325rem"
        paddingBottom={5}
      >
        Incidents updates
      </Heading>

      <Flex maxWidth="50%" alignItems="flex-end" gap={4}>
        <Stack flex={1} minWidth="50%" maxWidth="50%">
          {renderApplicationsDropDown()}
        </Stack>
      </Flex>

      <Flex maxWidth="50%" alignItems="flex-end" gap={4}>
        <Stack flex={1} minWidth="25%" maxWidth="25%">
          {renderActiveIncidentCheckbox()}
        </Stack>
        <Stack flex={1} minWidth="25%">
          {renderCountDowntimeCheckbox()}
        </Stack>
      </Flex>

      <Flex maxWidth="50%" alignItems="flex-end" gap={4}>
        <Stack flex={1} minWidth="50%" maxWidth="50%">
          {renderCustomStartDateTimeInput('Start date and time')}
        </Stack>
        {!incidentActiveValue && (
          <Stack flex={1}>
            {renderCustomEndDateTimeInput('End date and time')}
          </Stack>
        )}
      </Flex>

      {!incidentActiveValue && (
        <HStack maxWidth="50%">{renderCustomDowntimeInput()}</HStack>
      )}

      <Stack maxWidth="75%" spacing={6}>
        <Flex gap={4} alignItems="flex-end">
          {renderSummaryTextArea()}
          {renderRootCauseTextArea()}
        </Flex>
        <Flex gap={4} alignItems="flex-end">
          {renderRemediationTextArea()}
          {renderImpactTextArea()}
        </Flex>
      </Stack>

      <Stack maxWidth="75%">{renderJiraLinkInput()}</Stack>

      <Stack maxWidth="50%">{renderNewsletterInput()}</Stack>

      <HStack
        maxWidth="75%"
        spacing={4}
        justifyContent="flex-end"
        display="flex"
      >
        <SecondaryButton
          onClick={() => {
            mergeSearchParams({ appId: 'null', incidentId: 'null' });
            window.location.reload();
          }}
        >
          Cancel
        </SecondaryButton>

        <PrimaryButton onClick={() => updateIncidentData()}>
          Save incident
        </PrimaryButton>
      </HStack>
    </Stack>
  );
};
