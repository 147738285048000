import {
  Collapse,
  Flex,
  HStack,
  Link,
  LinkProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import {
  NavLink as NL,
  NavLinkProps as NLP,
  useLocation,
} from 'react-router-dom';
import { ConfigIcon } from '../icons/ConfigIcon';
import { GrafanaIcon } from '../icons/GrafanaIcon';
import { GraphIcon } from '../icons/GraphIcon';
import { TicketManagementIcon } from '../icons/TicketManagementIcon';
import { TicketStatisticsIcon } from '../icons/TicketStatisticsIcon';
import { ProtectedResource } from '../providers/PermissionProvider';

export type NavLinkProps = NLP & LinkProps;

export const NavLink: FC<NavLinkProps> = (props: NavLinkProps) => (
  <Link
    as={NL}
    color="#808285"
    fontWeight="light"
    _activeLink={{
      color: '#414042',
      textDecoration: 'none',
      ...props._activeLink,
    }}
    {...props}
  />
);

const NavItem = (
  props: { label: string; icon: ReactElement } & NavLinkProps,
) => {
  const { label, icon, ...navLinkProps } = props;

  return (
    <Flex as="li">
      <NavLink
        width="full"
        paddingX={3}
        paddingY={2}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          '&:not(.active) svg *': {
            fill: 'currentcolor',
          },
        }}
        {...navLinkProps}
      >
        <HStack>
          {icon}
          <Text fontSize="sm">{label}</Text>
        </HStack>
      </NavLink>
    </Flex>
  );
};

const NavGroup = ({
  main,
  children,
}: {
  main: ReactElement;
  children: ReactNode[];
}) => {
  const baseUrl = main.props.to as string;
  const { pathname } = useLocation();

  return (
    <Stack spacing={0}>
      {main}
      <Collapse in={pathname.startsWith(baseUrl)}>
        <Stack paddingStart={16} paddingBottom={2} spacing={3}>
          {Children.map(children, (child) => {
            if (!isValidElement(child)) {
              return child;
            }

            const url = child.props.to as string;
            const fullSublinkUrl = `${baseUrl}${url}`.replace(/\/$/, '');

            return cloneElement(child, {
              to: fullSublinkUrl,
            } as any);
          })}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NavSubitem = (props: { label: string } & NavLinkProps) => {
  const { label, ...navLinkProps } = props;

  return (
    <NavLink _activeLink={{ fontWeight: 'normal' }} {...navLinkProps}>
      <Text fontSize="xs">{label}</Text>
    </NavLink>
  );
};

export type LeftNavProps = {};

export const LeftNav: FC<LeftNavProps> = (props) => {
  return (
    <Flex
      as="aside"
      borderRight="1px solid"
      borderRightColor="gray.300"
      minWidth="200px"
    >
      <Flex as="nav">
        <Stack as="ul" listStyleType="none" spacing={0}>
          <ProtectedResource permissionsFor="ApplicationMonitor">
            <NavItem
              to="/monitor/availability"
              label="Availability monitor"
              icon={<GraphIcon boxSize={9} />}
            />
          </ProtectedResource>

          <ProtectedResource permissionsFor="TicketManagement">
            <NavItem
              to="/ticket-management"
              label="Ticket management"
              icon={<TicketManagementIcon boxSize={9} />}
            />
          </ProtectedResource>

          <ProtectedResource permissionsFor="TicketStatistic">
            <NavGroup
              main={
                <NavItem
                  to="/ticket-statistics"
                  icon={<TicketStatisticsIcon boxSize={9} />}
                  label="Ticket statistics"
                />
              }
            >
              <NavSubitem to="/" label="Incidents" end />
              <NavSubitem to="/resolution-time" label="Resolution time" />
            </NavGroup>
          </ProtectedResource>

          <ProtectedResource permissionsFor="Configuration">
            <NavItem
              to="/configuration/incident-new"
              label="Configuration"
              icon={<ConfigIcon boxSize={9} />}
            />
          </ProtectedResource>

          <ProtectedResource permissionsFor="GrafanaDashboard">
            <NavItem
              to="/grafana-dashboard"
              label="KPI Dashboard"
              icon={<GrafanaIcon boxSize={9} />}
            />
          </ProtectedResource>
        </Stack>
      </Flex>
    </Flex>
  );
};