import { Heading, HStack } from '@chakra-ui/react';
import { FC } from 'react';

export type IncidentOverviewHeaderProps = {
  appName: string;
};

export const IncidentOverviewHeader: FC<IncidentOverviewHeaderProps> = (
  props,
) => {
  const { appName } = props;

  return (
    <HStack>
      <Heading size="md" fontWeight="medium" color="#414042" fontSize="xl">
        {appName}
      </Heading>
    </HStack>
  );
};