import { FC } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { KPIMetric } from './useKPIMetricsQuery';
import { convertMsToHM } from '../../../utils/timeHelpers';
import { format } from 'date-fns';

export type KPIMetricDetailsProps = {
  metric: KPIMetric;
};

export const KPIMetricDetails: FC<KPIMetricDetailsProps> = (props) => {
  const { metric } = props;
  return (
    <Stack
      border="1px solid #e6e7e8"
      paddingTop={10}
      padding={5}
      spacing={5}
      width="max-content"
    >
      <Text>Name: {metric.name}</Text>
      <Text>Status: {metric.status}</Text>
      {metric.status !== 'OK' && <Text>State: {metric.state}</Text>}
      <Text>
        Updated: {format(new Date(metric.updatedAt), 'dd.MM.yyyy H:mm:ss')}
      </Text>
      <Text>{convertMsToHM(Date.now() - metric.updatedAt)} ago</Text>
    </Stack>
  );
};
