import { context, rest, RestHandler } from 'msw';

import { apiBaseUrl } from '../api/api';

import * as responses from './responses';
import { authorize } from './transformers';

const defaultRequestHandlers = [context.delay()];

export const createHandlers = (defaultHandlers = defaultRequestHandlers) => {
  const localMocks = [
    rest.get(`${apiBaseUrl}/dashboard`, (req, res) => {
      return res(...defaultHandlers, responses.dashboardData(), authorize(req));
    }),

    rest.delete(`${apiBaseUrl}/incident/:incidentId`, (req, res, ctx) => {
      return res(...defaultHandlers, ctx.status(204), authorize(req));
    }),

    rest.get(`${apiBaseUrl}/statistics/:viewType`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.statisticsTableData(req),
        authorize(req),
      );
    }),

    rest.get(`${apiBaseUrl}/application/screen`, (req, res, ctx) => {
      return res(...defaultHandlers, responses.applications(), authorize(req));
    }),

    rest.get(
      `${apiBaseUrl}/grafana/public-dashboards/token`,
      (req, res, ctx) => {
        return res(
          ...defaultHandlers,
          context.json({ token: 'token' }),
          authorize(req),
        );
      },
    ),

    rest.get(`${apiBaseUrl}/application/ticket`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.ticketApplications(),
        authorize(req),
      );
    }),
    rest.get(`${apiBaseUrl}/grafana/applications`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.grafanaApplicationsData(),
        authorize(req),
      );
    }),
    rest.get(`${apiBaseUrl}/incident/application/:appId`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.getIncidentsDetails(req),
        authorize(req),
      );
    }),
    rest.get(`${apiBaseUrl}/ticket/management`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.ticketIssues(req),
        authorize(req),
      );
    }),

    rest.get(`${apiBaseUrl}/ticket/management/chart`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.ticketManagementCharts(req),
        authorize(req),
      );
    }),

    rest.get(`${apiBaseUrl}/ticket/management/chart`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.ticketManagementCharts(req),
        authorize(req),
      );
    }),

    rest.get(`${apiBaseUrl}/ticket/management/month-list`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.availableMonthsData(),
        authorize(req),
      );
    }),

    // ticket-statistic/incidents -> table data
    rest.get(`${apiBaseUrl}/ticket/incident`, (req, res) => {
      return res(
        ...defaultHandlers,
        responses.ticketStatisticData(),
        authorize(req),
      );
    }),

    // configuration incident details per application
    rest.get(
      `${apiBaseUrl}/ticket/incident/application/:applicationId`,
      (req, res, ctx) => {
        return res(
          ...defaultHandlers,
          responses.getIncidentsPerApplication(req),
          authorize(req),
        );
      },
    ),

    // ticket-statistic/incidents -> both charts: incidents and trends
    rest.get(`${apiBaseUrl}/ticket/incident/chart`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.trendGraphDetails(),
        authorize(req),
      );
    }),
    //Resolution time:
    //table and hours chart (the double one)
    rest.get(`${apiBaseUrl}/ticket/resolution-time`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.resolutionTableAndHoursChartData(),
        authorize(req),
      );
    }),

    // ticket-statistics/resolution-time -> trend chart
    rest.get(`${apiBaseUrl}/ticket/resolution-time/chart`, (req, res, ctx) => {
      return res(
        ...defaultHandlers,
        responses.resolutionTimeTrendChartData(),
        authorize(req),
      );
    }),

    // configuration incident details
    rest.get(`${apiBaseUrl}/incident/details/:incidentId`, (req, res, ctx) => {
      const { incidentId } = req.params;
      return res(
        ...defaultHandlers,
        incidentId === '0' ? ctx.status(204) : responses.configurationData(),
        authorize(req),
      );
    }),

    rest.post(`${apiBaseUrl}/token`, async (req, res) => {
      return res(...defaultHandlers, await responses.getToken(req));
    }),

    rest.post(`${apiBaseUrl}/token/refresh`, async (req, res) => {
      return res(...defaultHandlers, await responses.getRefreshedTokens(req));
    }),

    rest.post(`${apiBaseUrl}/token/logout`, async (req, res) => {
      return res(
        ...defaultHandlers,
        await responses.getToken(req),
        authorize(req),
      );
    }),

    rest.get(`${apiBaseUrl}/kpi/:appId/metrics`, async (req, res) => {
      return res(...defaultHandlers, responses.kpiMetrics(), authorize(req));
    }),
  ];

  const prodOnlyMocks: RestHandler[] = [];

  return [
    ...(process.env.NODE_ENV !== 'production' ? localMocks : prodOnlyMocks),
  ];
};