import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useMeasure } from 'react-use';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { roundToPlaces } from '../../../utils/mathHelpers';
import { millisecondsToHours } from '../../../utils/timeHelpers';
import { TrendChartQuery } from './useTicketStatisticsResolutionTimeTrendChartQuery';
import {
  getColorFromSeverity,
  getNameFromSeverity,
} from '../ticketStatisticOverview/TicketStatisticTrendsGraph';

type ResolutionTimeTrendChartProps = {
  data: TrendChartQuery;
};

export const TicketStatisticResolutionTimeTrendChart: FC<
  ResolutionTimeTrendChartProps
> = (props) => {
  const { data: trendGraphData } = props;
  const { entries, periods, severities } = trendGraphData;

  const [chartContainerRef, chartContainerDimens] =
    useMeasure<HTMLDivElement>();

  const chartData = useMemo(() => {
    const sortedPeriods = Array.from(periods).sort(
      ({ dateTime: a }, { dateTime: b }) => {
        if (a === b) return 0;
        return a > b ? 1 : -1;
      },
    );

    return sortedPeriods.map((period) => {
      const { dateTime, id } = period;

      const entryByPeriod = entries.filter(({ periodId }) => periodId === id);

      return {
        ...Object.fromEntries(
          severities.map(({ id }) => {
            const entry = entryByPeriod.find(
              ({ severityId }) => severityId === id,
            ) ?? {
              averageResolutionTime: 0,
              periodId: period.id,
              severityId: id,
            };

            const formattedResTime = roundToPlaces(
              millisecondsToHours(entry.averageResolutionTime),
              1,
            );

            return [id, formattedResTime];
          }),
        ),
        name: format(new Date(dateTime), 'MMM yyyy'),
      };
    });
  }, [entries, periods, severities]);

  const renderColorfulLegendText = (value: string) => {
    return (
      <Box
        as="span"
        fontSize="10px"
        color="#414042"
        padding={3}
        textTransform="capitalize"
      >
        {value}
      </Box>
    );
  };

  return (
    <Stack height="full">
      <Stack marginTop={10} border="1px solid #e6e7e8" padding={0} flex={1}>
        <Flex
          backgroundColor="#e6e7e8"
          textAlign="end"
          width="full"
          paddingLeft={4}
          paddingRight={4}
          paddingTop={3}
          paddingBottom={3}
          justifyContent="space-between"
        >
          <Text color="#414042">Average resolution time</Text>
          <Text color="#808285">Last 6 months</Text>
        </Flex>

        <Box ref={chartContainerRef} flex={1} width="full">
          <ResponsiveContainer
            width={chartContainerDimens.width}
            height={chartContainerDimens.height}
          >
            <ComposedChart data={chartData}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="name"
                domain={['0', '0']}
                tickMargin={10}
                fontSize={12}
                interval={0}
              />
              <YAxis
                fontSize={12}
                interval={0}
                domain={[
                  0,
                  (dataMax: number) => Math.max(Math.ceil(dataMax), 16),
                ]}
                type="number"
                scale="linear"
              />
              <Legend
                layout="horizontal"
                align="center"
                verticalAlign="top"
                iconSize={20}
                wrapperStyle={{ paddingBottom: '5px' }}
                iconType="circle"
                formatter={renderColorfulLegendText}
              />

              {severities.map(({ id, severity, thirdPartyCause }) => {
                return (
                  <Bar
                    key={id}
                    dataKey={id}
                    fill={getColorFromSeverity(severity)}
                    name={getNameFromSeverity(severity)}
                  />
                );
              })}
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Stack>
  );
};
