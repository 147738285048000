import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useApi } from '../../../api/ApiProvider';
import { ApiResponse } from '../../../api/types';

export const createGetKPIMetricsCacheKey = () => ['GetKPIMetricsQuery'];

export type KPIMetric = {
  applicationId: string;
  id: string;
  name: string;
  status: 'OK' | 'EXCEEDED' | 'BREACHED';
  state: string;
  updatedAt: number;
};

export type KPIMetrics = KPIMetric[];

type QueryResult = ApiResponse<KPIMetrics>;

export const useKPIMetricsQuery = (
  appId: string,
  opts?: UseQueryOptions<QueryResult, AxiosError>,
  axios?: AxiosRequestConfig,
) => {
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<KPIMetrics>(`/kpi/${appId}/metrics`, {
      ...axios,
      signal,
    });
    return data;
  };

  return useQuery<QueryResult, AxiosError>(
    createGetKPIMetricsCacheKey(),
    queryFn,
    opts,
  );
};
