import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useMeasure } from 'react-use';
import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { IncidentsTrendChartQuery } from './useTicketStatisticsTrendChartQuery';

type TicketStatisticTrendsGraphProps = {
  data: IncidentsTrendChartQuery;
  showThirdPartyCause: boolean;
};

export const getColorFromSeverity = (severity: string) => {
  return severity === 'BLOCKER' ? '#dc0032ff' : '#ffc004';
};

export const getNameFromSeverity = (severity: string) => {
  return severity.toLowerCase();
};

export const TicketStatisticTrendsGraph: FC<TicketStatisticTrendsGraphProps> = (
  props,
) => {
  const { data: trendGraphData, showThirdPartyCause } = props;
  const { entries, severities, periods } = trendGraphData;

  const [chartContainerRef, chartContainerDimens] =
    useMeasure<HTMLDivElement>();

  const graphData = useMemo(() => {
    const sortedPeriods = Array.from(periods).sort(
      ({ dateTime: a }, { dateTime: b }) => {
        if (a === b) return 0;
        return a > b ? 1 : -1;
      },
    );

    return sortedPeriods.map((period) => {
      const { dateTime, id } = period;

      const entryByPeriod = entries.filter(({ periodId }) => periodId === id);

      const data = Object.fromEntries(
        entryByPeriod.map((entry) => {
          const { severityId, numberOfTickets } = entry;
          return [severityId, numberOfTickets];
        }),
      );

      return {
        ...data,
        name: format(new Date(dateTime), 'MMM yyyy'),
      };
    });
  }, [entries, periods]);

  return (
    <Stack height="full">
      <Stack marginTop={10} border="1px solid #e6e7e8" padding={0} flex={1}>
        <Flex
          backgroundColor="#e6e7e8"
          textAlign="end"
          width="full"
          paddingLeft={4}
          paddingRight={4}
          paddingTop={3}
          paddingBottom={3}
          justifyContent="space-between"
        >
          <Text color="#414042">Blocker and critical incidents trend</Text>
          <Text color="#808285">Last 6 months</Text>
        </Flex>

        <Box ref={chartContainerRef} flex={1} width="full">
          <ResponsiveContainer
            width={chartContainerDimens.width}
            height={chartContainerDimens.height}
          >
            <LineChart
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tickMargin={10}
                fontSize={12}
                interval={0}
              />
              <YAxis tickMargin={10} fontSize={12} interval={0} />
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                iconType="circle"
                iconSize={20}
                wrapperStyle={{ right: '0' }}
              />

              {severities
                .filter(
                  ({ thirdPartyCause }) =>
                    thirdPartyCause === showThirdPartyCause,
                )
                .map(({ severity, id }) => {
                  return (
                    <Line
                      key={id}
                      dataKey={id}
                      stroke={getColorFromSeverity(severity)}
                      name={getNameFromSeverity(severity)}
                      type="monotone"
                      activeDot={{ r: 8 }}
                    >
                      <LabelList
                        dataKey={id}
                        position="top"
                        style={{ fontSize: '10px', color: '#414042' }}
                      />
                    </Line>
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Stack>
  );
};
