import { FC } from 'react';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { IncidentsTable } from './IncidentsTable';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { IncidentDetails } from '../useIncidentDetailsQuery';

export type IncidentsSectionProps = {
  periodLabel: String;
  incidentDetails: IncidentDetails;
  appId: string;
  setIncidentIdToRemove: (incidentIdToRemove: string) => void;
  renderModal: () => JSX.Element | null;
};

export const IncidentsSection: FC<IncidentsSectionProps> = (props) => {
  const {
    periodLabel,
    incidentDetails,
    appId,
    setIncidentIdToRemove,
    renderModal,
  } = props;
  const navigate = useNavigate();

  return (
    <Stack border="1px solid #e6e7e8" flex={1} height={0}>
      <Stack padding={5} spacing={5} flex={1} overflowY="auto">
        <Flex justify="space-between">
          <Heading as="h5" size="md" fontWeight="medium">
            Incidents
          </Heading>

          <Text fontSize="md" color="#808285">
            {periodLabel}
          </Text>
        </Flex>

        <Flex
          justifyContent="flex-end"
          width="full"
          align="center"
          marginTop={0}
        >
          <Box
            display="flex"
            alignItems="center"
            onClick={() => navigate('/configuration/incident-new')}
            cursor="pointer"
          >
            <BsPlus color="red" size="2em" />
            <Text fontWeight="normal" color="#414042" fontSize="sm">
              Add incident
            </Text>
          </Box>
        </Flex>

        <Stack flex={1} height={0} overflowY="auto">
          <IncidentsTable
            incidents={incidentDetails.incidents}
            appId={appId}
            onDeleteIncident={(incidentId) => setIncidentIdToRemove(incidentId)}
          />
          {renderModal()}
        </Stack>
      </Stack>
    </Stack>
  );
};
