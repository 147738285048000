import { FC, useState } from 'react';
import {
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CircleIcon } from '../../../icons/CircleIcon';
import { KPIMetricDetails } from './KPIMetricDetails';
import { KPIMetric, useKPIMetricsQuery } from './useKPIMetricsQuery';
import { RingIcon } from '../../../icons/RingIcon';

export type KPISectionProps = {
  appId: string;
};

export const KPISection: FC<KPISectionProps> = (props) => {
  const [selectedMetric, setSelectedMetric] = useState<KPIMetric | null>(null);
  const selectMetric = (metric: KPIMetric) => {
    if (selectedMetric?.id === metric.id) {
      setSelectedMetric(null);
    } else {
      setSelectedMetric(metric);
    }
  };

  const { appId } = props;

  const {
    data: metricsData,
    error: metricsError,
    isLoading: metricsIsLoading,
  } = useKPIMetricsQuery(appId);

  const renderKPIIcons = () => {
    if (metricsError) {
      return <Text>Error loading...</Text>;
    }

    if (metricsIsLoading) {
      return <Spinner />;
    }

    const calculateColor = (metric: KPIMetric) => {
      switch (metric.status) {
        case 'OK':
          return 'green.400';
        case 'EXCEEDED':
          return '#ffc400';
        case 'BREACHED':
          return 'red.500';
      }
    };

    return metricsData.map((metric: KPIMetric) => {
      return (
        <Link onClick={() => selectMetric(metric)}>
          {metric.id === selectedMetric?.id ? (
            <RingIcon boxSize={6} color={calculateColor(metric)} />
          ) : (
            <CircleIcon boxSize={6} color={calculateColor(metric)} />
          )}
        </Link>
      );
    });
  };

  if (!metricsData || metricsData.length === 0) {
    return <></>;
  }

  return (
    <Stack border="1px solid #e6e7e8">
      <Stack padding={5} spacing={5} flex={1} overflowY="auto">
        <Flex justify="space-between">
          <HStack spacing={5}>
            <Heading as="h5" size="md" fontWeight="medium">
              KPI metrics
            </Heading>

            {renderKPIIcons()}
          </HStack>
          <Text fontSize="md" color="#808285">
            Click icons for more details
          </Text>
        </Flex>

        {selectedMetric && (
          <Stack flex={1} height={0} spacing={5}>
            <KPIMetricDetails metric={selectedMetric} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
