import {
  HStack,
  Link,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import { SuccessIcon } from '../../../icons/SuccessIcon';

import { IncidentDetail } from './types';

export type IncidentsTableProps = {
  incidents: IncidentDetail[];
} & TableProps;

export const IncidentsTable: FC<IncidentsTableProps> = (props) => {
  const { incidents, ...tableProps } = props;

  const columns = useMemo(
    () =>
      [
        {
          Header: 'No.',
          accessor: 'index',
          Cell: ({ row }: { row: { index: number } }) => row.index + 1,
        },
        {
          Header: 'Incident type',
          accessor: 'severity',
          Cell: ({ value }) => (
            <Text whiteSpace="nowrap">
              {value?.slice(0, 1).toUpperCase()}
              {value?.slice(1).toLowerCase()}
            </Text>
          ),
        },
        {
          Header: 'Start date and time',
          accessor: 'startDateTime',
          Cell: ({ value }) => (
            <Text whiteSpace="nowrap">
              {format(new Date(value), 'dd.MM.yyyy, H:mm:ss')}
            </Text>
          ),
        },
        {
          Header: 'End date and time',
          accessor: 'endDateTime',
          Cell: ({ value }) => (
            <Text whiteSpace="nowrap">
              {value && format(new Date(value), 'dd.MM.yyyy, H:mm:ss')}
            </Text>
          ),
        },
        {
          Header: 'Jira issue',
          accessor: 'jiraUrl',
          Cell: ({ value }) =>
            value ? (
              <Link href={value} isExternal color="#3295cf">
                {value}
              </Link>
            ) : (
              ''
            ),
        },
        {
          Header: 'Id',
          accessor: 'id',
        },
      ] as Column<IncidentDetail>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const data = useMemo(
    () =>
      Object.entries(incidents).map(([incidentId, incident]) => ({
        ...incident,
        incidentId,
      })),
    [incidents],
  );

  const initialState = {
    hiddenColumns: ['id'],
    sortBy: [
      {
        id: 'startTime',
        desc: false,
      },
    ],
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: rowsOriginal,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
  );

  const rows = rowsOriginal.map((row, i) => ({
    ...row,
    index: i,
  }));

  const renderTableHead = () => {
    return (
      <Thead backgroundColor="#414042" position="sticky" top={0} zIndex={1}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps()}
                textTransform="none"
                color="#ffffff"
                fontSize="s"
                fontWeight="normal"
                maxWidth={column.maxWidth}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
    );
  };

  const renderTableBody = () => {
    if (rows.length === 0) {
      return (
        <Tr>
          <Td colSpan={columns.length} paddingY={5}>
            <HStack justify="center">
              <SuccessIcon fill="green" />
              <Text>There are no incidents in the chosen time period</Text>
            </HStack>
          </Td>
        </Tr>
      );
    }

    const renderBodyRows = () => {
      return rows.map((row) => {
        prepareRow(row);

        return (
          <Tr borderBottom="1px solid #e6e7e8" {...row.getRowProps()}>
            {row.cells.map((cell) => {
              const cellProps = cell.getCellProps();
              return (
                <Td
                  {...cellProps}
                  fontSize="xs"
                  wordBreak="break-word"
                  verticalAlign="top"
                  align="left"
                  paddingLeft={6}
                >
                  {cell.render('Cell')}
                </Td>
              );
            })}
          </Tr>
        );
      });
    };

    return <Tbody {...getTableBodyProps()}>{renderBodyRows()}</Tbody>;
  };

  return (
    <Table
      border="1px solid #e6e7e8"
      position="relative"
      {...tableProps}
      {...getTableProps()}
    >
      {renderTableHead()}
      {renderTableBody()}
    </Table>
  );
};
