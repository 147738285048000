import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useApplicationsTicketQuery } from './queries/useApplicationsTicketQuery';
import { useTicketManagementIssueQuery } from './ticketManagementPage/useTicketManagementIssueQuery';
import { TicketsRemainingChart } from './ticketManagementPage/TicketsRemainingChart';
import { TicketManagementTable } from './ticketManagementPage/TicketManagementTable';
import { TimeSpentChart } from './ticketManagementPage/TimeSpentChart';
import {
  isTicketSupportLevel,
  ticketSupportLevels,
} from './ticketManagementPage/ticketManagementDomain';
import { FormControl, FormLabel, Select, SelectProps } from '@dpd-ui/core';
import { useTicketManagementAvailableMonthsQuery } from './ticketManagementPage/useTicketManagementAvailableMonthsQuery';

type TicketManagementPageProps = {};

const supportLevelOptions = ticketSupportLevels.map((id) => ({ name: id, id }));

export const calculatePreviousEndedMonths = (numberOfMonths: number) => {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let date: [number, number][] = [];

  for (let i = 0; i < numberOfMonths; i++) {
    if (currentMonth < 1) {
      currentMonth = 12;
      currentYear -= 1;
    }

    date.push([currentYear, currentMonth]);
    currentMonth -= 1;
  }
  return date;
};

const lastEndedMonths = calculatePreviousEndedMonths(12).map(
  ([currentYear, currentMonth]) =>
    `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`,
);

export const TicketManagementPage: FC<TicketManagementPageProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    periodValue: lastEndedMonths[0],
  });

  const parsed = Object.fromEntries(searchParams);
  const {
    periodValue,
    selectedApps,
    supportLevel: rawQuerySupportLevel,
  } = parsed;

  const supportLevel = isTicketSupportLevel(rawQuerySupportLevel)
    ? rawQuerySupportLevel
    : null;

  const selectedApplicationIds = useMemo(
    () => (selectedApps ? selectedApps.split(',') : []),
    [selectedApps],
  );

  const applicationsQuery = useApplicationsTicketQuery(
    {},
    {
      params: { screen: 'TICKET_MANAGEMENT' },
    },
  );

  const ticketManagementIssuesQuery = useTicketManagementIssueQuery(
    {
      periodValue,
      supportLevel,
    },
    {
      enabled: applicationsQuery.isSuccess || !selectedApplicationIds.length,
    },
  );

  const availableMonthsQuery = useTicketManagementAvailableMonthsQuery();

  const tableData = useMemo(() => {
    const { data } = ticketManagementIssuesQuery;
    if (!data) return null;
    if (selectedApplicationIds.length === 0) return data;

    const allAppTickets = data.tickets;
    return {
      ...data,
      tickets: allAppTickets.filter(({ id }) =>
        selectedApplicationIds.includes(id),
      ),
    };
  }, [ticketManagementIssuesQuery, selectedApplicationIds]);

  const mergeSearchParams = (obj: typeof parsed) => {
    setSearchParams({
      ...parsed,
      ...obj,
    });
  };

  if (
    ticketManagementIssuesQuery.isError ||
    applicationsQuery.isError ||
    availableMonthsQuery.isError
  ) {
    return (
      <Center height="full" width="full">
        <Alert
          status="error"
          as={Stack}
          width="90%"
          maxWidth="400px"
          borderRadius="lg"
          border="1px solid"
          borderColor="red.300"
        >
          <HStack>
            <AlertIcon />
            <Text>
              Error:
              {(
                (ticketManagementIssuesQuery.error ||
                  applicationsQuery.error) as Error
              )?.message ?? 'Something went wrong'}
            </Text>
          </HStack>
          <Button onClick={() => window.location.reload()}>
            Refresh the page
          </Button>
        </Alert>
      </Center>
    );
  }

  if (applicationsQuery.isLoading || availableMonthsQuery.isLoading) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  const applications = applicationsQuery.data!;

  const renderMonthFilter = () => {
    const options = availableMonthsQuery.data
      ?.sort((lhs, rhs) =>
        lhs.year > rhs.year || lhs.month > rhs.month ? -1 : 1,
      )
      .map(({ month, year }) => {
        const simpleDate = `${year}-${month.toString().padStart(2, '0')}-01`;

        return {
          label: format(new Date(simpleDate), 'LLLL yyyy'),
          value: simpleDate,
        };
      });

    return (
      <FormControl flex={1}>
        <Select
          options={options}
          value={periodValue}
          onChange={(value) => {
            value !== null &&
              mergeSearchParams({
                periodValue: value,
              });
          }}
        />
        <FormLabel>Choose period</FormLabel>
      </FormControl>
    );
  };

  const renderAppsFilter = () => {
    const options: SelectProps['options'] = [
      { label: 'All apps', value: '' },
      ...Array.from(applications)
        .sort(({ name: a }, { name: b }) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        })
        .map(({ name, id }) => ({
          value: id,
          label: name,
        })),
    ];
    return (
      <FormControl flex={1}>
        <Select
          value={selectedApplicationIds[0] ?? ''}
          onChange={(value) =>
            value !== null &&
            mergeSearchParams({
              selectedApps: value ?? '',
            })
          }
          options={options}
        />
        <FormLabel>Choose application</FormLabel>
      </FormControl>
    );
  };

  const renderSupportLevelFilter = () => {
    const options = [
      { label: 'All support levels', value: '' },
      ...supportLevelOptions.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ];

    return (
      <FormControl flex={1}>
        <Select
          options={options as any}
          value={supportLevel ?? ''}
          onChange={(value) =>
            mergeSearchParams({
              supportLevel: value || '',
            })
          }
        />
        <FormLabel>Choose support level</FormLabel>
      </FormControl>
    );
  };

  return (
    <Stack flex={1} padding={5} spacing={6} width={0}>
      <Heading
        fontWeight="light"
        color="rgba(65, 64, 66, 1)"
        fontSize="1.325rem"
        paddingBottom={5}
      >
        Issues in Backlog - IN/OUT/Remain
      </Heading>

      <Wrap maxWidth="50%" overflow="visible">
        {renderMonthFilter()}
        {renderAppsFilter()}
        {renderSupportLevelFilter()}
      </Wrap>

      <Stack flex={1} height={0} overflowY="auto" spacing={10}>
        <Stack
          direction={['column', null, null, null, 'row']}
          minHeight={['1000px', null, null, null, '600px']}
          alignItems="stretch"
          spacing={5}
        >
          <Box flex={1}>
            <TicketsRemainingChart
              applications={applications}
              periodValue={periodValue}
              supportLevel={supportLevel}
            />
          </Box>

          <Box flex={1}>
            <TimeSpentChart
              applications={applications}
              periodValue={periodValue}
              supportLevel={supportLevel}
            />
          </Box>
        </Stack>

        {ticketManagementIssuesQuery.isLoading ? (
          <Center flex={1} height="full">
            <Spinner />
          </Center>
        ) : (
          <TicketManagementTable
            // TODO: ensure minHeight
            // containerProps={{ height: 'full' }}
            data={tableData!}
          />
        )}
      </Stack>
    </Stack>
  );
};
