import { isAutorized } from './../transformers';
import { context, RestRequest } from 'msw';
import * as jwt from '../../utils/jwtHelpers';
import { getTimeInSeconds } from '../../utils/timeHelpers';

type Scope = 'ADMIN' | 'GSA' | 'BU' | 'USER' | 'GRAFANA' | 'PROJECT_MANAGER';

// NOTE: you can modify in what scope is user
const scopes: Scope[] = ['ADMIN'];

export const commonUserData = {
  name: 'test name',
  scope: scopes.join(' '),
  sub: 1,
};

const generateTokens = (req: RestRequest) => {
  const userData = {
    iat: Date.now(),
    ...commonUserData,
  };

  return {
    accessToken: jwt.encode(
      {
        ...userData,
        exp: getTimeInSeconds() + 60 * 100,
      },
      '',
    ),
    refreshToken: jwt.encode(
      {
        ...userData,
        exp: getTimeInSeconds() + 60 * 30000,
      },
      '',
    ),
  };
};

export const getToken = async (req: RestRequest) => {
  return context.json(generateTokens(req));
};

export const getRefreshedTokens = async (req: RestRequest) => {
  if (!isAutorized(req)) {
    return context.status(403);
  }

  return context.json(generateTokens(req));
};
