import { Button, HStack, Link, LinkProps, Spacer } from '@chakra-ui/react';
import { FC } from 'react';
import { NavLink as NL, NavLinkProps as NLP } from 'react-router-dom';

import { GeopostIcon } from '../icons/GeopostIcon';
import { useAuth } from '../providers/AuthProvider';
import { ProtectedResource } from '../providers/PermissionProvider';

export type NavLinkProps = NLP & LinkProps;

export const NavLink: FC<NavLinkProps> = (props: NavLinkProps) => (
  <Link
    as={NL}
    fontSize="sm"
    _activeLink={{
      textDecoration: 'underline',
      color: 'rgba(220, 0, 50, 1)',
    }}
    {...props}
  />
);

export const Logo = () => {
  return (
    <NL to="/">
      <GeopostIcon paddingLeft={6} height={'60px'} />
    </NL>
  );
};

export const Nav = () => {
  return (
    <HStack
      spacing={7}
      padding={5}
      paddingLeft={14}
      height={5}
      boxSizing="border-box"
      fontWeight="light"
    >
      <ProtectedResource permissionsFor="ApplicationMonitor">
        <NavLink to="/monitor/availability">Availability monitor</NavLink>
      </ProtectedResource>

      <ProtectedResource permissionsFor="TicketManagement">
        <NavLink to="/ticket-management">Ticket management</NavLink>
      </ProtectedResource>

      <ProtectedResource permissionsFor="TicketStatistic">
        <NavLink to="/ticket-statistics">Ticket statistics</NavLink>
      </ProtectedResource>

      <ProtectedResource permissionsFor="Configuration">
        <NavLink to="/configuration/incident-new">Configuration</NavLink>
      </ProtectedResource>

      <ProtectedResource permissionsFor="GrafanaDashboard">
        <NavLink to="/grafana-dashboard">KPI Dashboard</NavLink>
      </ProtectedResource>
    </HStack>
  );
};

const LogoutButton = () => {
  const auth = useAuth();
  return auth.state === 'signedIn' ? (
    <Button variant="link" onClick={auth.signOut}>
      Logout
    </Button>
  ) : null;
};

export const Header = () => {
  return (
    <HStack
      paddingY={0}
      paddingX={8}
      borderBottom="solid 1px"
      borderBottomColor="gray.300"
    >
      <Logo />
      <Nav />
      <Spacer />
      <LogoutButton />
    </HStack>
  );
};