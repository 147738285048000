import {
  Box,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, Fragment, useMemo } from 'react';
import { Column, Row, useGroupBy, useTable } from 'react-table';
import { TicketStatisticsIncidentsGraphData } from './useTicketStatisticsTableAndIncidentsQuery';
import { TicketStatisticIncidentsModal } from '../TicketStatisticIncidentsModal';
import { MagnifierIcon } from '../../../icons/MagnifierIcon';

type OpenIncidentListButtonProps = {
  appId: string;
  monthValue: string;
};

const OpenIncidentListButton = ({
  appId,
  monthValue,
}: OpenIncidentListButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MagnifierIcon
        cursor="pointer"
        height={30}
        width={'auto'}
        onClick={onOpen}
      />
      <TicketStatisticIncidentsModal
        appId={appId}
        monthValue={monthValue}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

type TicketStatisticTableProps = {
  data: TicketStatisticsIncidentsGraphData;
  monthValue: string;
};

export const TicketStatisticTable: FC<TicketStatisticTableProps> = (props) => {
  const { data: tableData, monthValue } = props;

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Application',
        accessor: 'appName',
      },
      {
        Header: 'Blocker',
        accessor: 'blocker',
      },
      {
        Header: 'Blocker (3rd party)',
        accessor: 'blockerThirdParty',
      },
      {
        Header: 'Critical',
        accessor: 'critical',
      },
      {
        Header: 'Critical (3rd party)',
        accessor: 'criticalThirdParty',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },

      {
        Header: () => (
          <Box width="full" textAlign="center">
            Tickets list
          </Box>
        ),
        Cell: ({ row: { values } }) => {
          return (
            <Box width="full" textAlign="center">
              <OpenIncidentListButton
                appId={values.appId}
                monthValue={monthValue}
              />
            </Box>
          );
        },
        accessor: '_',
        maxWidth: 1,
      },
      { Header: 'appGroupId', accessor: 'appGroupId' },
      { Header: 'appId', accessor: 'appId' },
    ],
    [monthValue],
  );

  const data = useMemo(() => {
    const { tickets } = tableData;

    return tickets
      .filter(({ total }) => total > 0)
      .map((app) => {
        const { name, applicationGroupId } = app;

        return {
          appId: app.id,
          appName: name,
          appGroupId: applicationGroupId,
          ...app,
        };
      });
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          groupBy: ['appGroupId'],
          hiddenColumns: ['appGroupId', 'appId'],
        } as any,
      },
      useGroupBy,
    );

  const renderTableHeader = () => {
    return (
      <Thead backgroundColor="#414042" position="sticky" top={0} zIndex={1}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps()}
                textTransform="none"
                color="#ffffff"
                fontSize="s"
                fontWeight="normal"
                maxWidth={column.maxWidth}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
    );
  };

  const renderTableBody = () => {
    const renderAppGroupRow = (row: Row) => {
      const { cells, values } = row;
      const [headerGroupName] = cells;
      const { appGroupId } = values;

      const appGroups = Object.values(tableData.applicationGroups);
      const group = appGroups.find(({ id }) => id === appGroupId);

      const columnsLength = columns.length;

      if (!group) {
        return null;
      }

      return (
        <Tr
          {...row.getRowProps()}
          key={row.getRowProps().key}
          padding={0}
          boxShadow="lg"
          position="relative"
          color="#808285"
          bg="#e6e7e8"
        >
          <Td
            {...headerGroupName.getCellProps()}
            position="sticky"
            left={0}
            paddingTop={1}
            paddingBottom={1}
            paddingLeft={6}
            colSpan={columnsLength}
            fontSize="s"
          >
            {group?.name || 'Unknown group'}
          </Td>
        </Tr>
      );
    };

    const renderAppRow = (row: any) => {
      return row.leafRows.map((leafRow: any) => {
        prepareRow(leafRow);

        return (
          <Tr {...leafRow.getRowProps()}>
            {leafRow.cells.map((cell: any) => {
              return (
                <Td
                  {...cell.getCellProps()}
                  fontSize="s"
                  color="#414042"
                  paddingTop={3}
                  paddingBottom={3}
                  paddingLeft={6}
                >
                  {cell.render('Cell')}
                </Td>
              );
            })}
          </Tr>
        );
      });
    };

    return (
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Fragment key={row.getRowProps().key}>
              {renderAppGroupRow(row)}
              {renderAppRow(row)}
            </Fragment>
          );
        })}
      </Tbody>
    );
  };

  return (
    <Stack flex={1}>
      <Table {...getTableProps()}>
        {renderTableHeader()}
        {renderTableBody()}
      </Table>
    </Stack>
  );
};
