import {
  Alert,
  AlertIcon,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useApplicationsQuery } from '../applicationMonitorPage/useApplicationsQuery';
import { useIncidentDetailsQuery } from './ticketStatisticIncidentsModal/useIncidentDetailsQuery';
import { IncidentsTable } from './ticketStatisticIncidentsModal/IncidentsTable';

interface TicketStatisticIncidentsModalProps {
  isOpen: boolean;
  appId: string;
  monthValue: string;

  onClose(): void;
}

export const TicketStatisticIncidentsModal = ({
  appId,
  monthValue,
  isOpen,
  onClose,
}: TicketStatisticIncidentsModalProps) => {
  const incidentDetailsQuery = useIncidentDetailsQuery(
    {
      appId,
      periodValue: format(new Date(monthValue), 'yyyy-MM-dd'),
    },
    {
      enabled: isOpen,
    },
  );

  const applicationsQuery = useApplicationsQuery(
    {},
    {
      params: { screen: 'TICKET_STATISTICS' },
    },
  );

  const isLoading = [
    incidentDetailsQuery.isLoading,
    applicationsQuery.isLoading,
  ].some(Boolean);

  const isFetching = [
    incidentDetailsQuery.isFetching,
    applicationsQuery.isFetching,
  ].some(Boolean);

  const isSuccess = [
    incidentDetailsQuery.isSuccess,
    applicationsQuery.isSuccess,
  ].every(Boolean);

  const isError = [
    incidentDetailsQuery.isError,
    applicationsQuery.isError,
  ].some(Boolean);

  const refetch = () => {
    incidentDetailsQuery.refetch();
    applicationsQuery.refetch();
  };

  const appName = applicationsQuery.data?.find(
    (application) => application.id === appId,
  )?.name;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      size={'5xl'}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <ModalCloseButton />
        {isSuccess && (
          <>
            <ModalHeader>{appName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={6}>
                <Text size="sm">Incidents extended view</Text>
                <IncidentsTable incidents={incidentDetailsQuery.data || []} />
              </Stack>
            </ModalBody>
            <ModalFooter alignSelf="center">
              <Button colorScheme="dpdRed" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
        {isError && !isFetching && (
          <Center height="sm" width="full">
            <Alert
              status="error"
              as={Stack}
              width="full"
              maxWidth="400px"
              borderRadius="lg"
              border="1px solid"
              borderColor="red.300"
            >
              <VStack>
                <AlertIcon />
                <Text>
                  Error:
                  {(
                    (incidentDetailsQuery.error ||
                      applicationsQuery.isError) as Error
                  ).message ?? 'Something went wrong'}
                </Text>
              </VStack>
              <Button onClick={() => refetch()}>Try again</Button>
            </Alert>
          </Center>
        )}
        {isLoading && (
          <Center height="sm" width="full">
            <Spinner />
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};
