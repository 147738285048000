import {
  Stack,
  StackProps,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FC, Fragment, useMemo } from 'react';
import { Column, Row, useGroupBy, useTable } from 'react-table';
import { TicketManagementData } from './useTicketManagementIssueQuery';
import { TicketType } from './ticketManagementDomain';
import { TicketManagementAppData } from '../applicationMonitorPage/types';
import {
  eqBy,
  groupBy,
  join,
  map,
  pipe,
  prop,
  sum,
  uniqBy,
  values,
} from 'ramda';

const ticketTypeAbbrevation: Record<TicketType, string> = {
  INCIDENT: 'Inc',
  SERVICE_REQUEST: 'Sr',
};

const createKeyByTicketType = (key: string, ticketType: TicketType) =>
  `${key}${ticketTypeAbbrevation[ticketType]}`;

type TicketManagementTableProps = {
  data: TicketManagementData;
  containerProps?: StackProps;
};

export const TicketManagementTable: FC<TicketManagementTableProps> = (
  props,
) => {
  const { data: tableData, containerProps } = props;

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Application',
        accessor: 'appName',
      },
      {
        Header: 'Support Level',
        accessor: 'supportLevel',
      },
      {
        Header: 'IN (Inc)',
        accessor: 'ticketsInInc',
        Cell: ({ value }) => value || 0,
      },
      {
        Header: 'IN (SR)',
        accessor: 'ticketsInSr',
        Cell: ({ value }) => value || 0,
      },
      {
        Header: 'OUT (Inc)',
        accessor: 'ticketsOutInc',
        Cell: ({ value }) => value || 0,
      },
      {
        Header: 'OUT (SR)',
        accessor: 'ticketsOutSr',
        Cell: ({ value }) => value || 0,
      },
      {
        Header: 'Remain in total (Inc)',
        accessor: 'remainInTotalInc',
        Cell: ({ value }) => value || 0,
      },
      {
        Header: 'Remain in total (SR)',
        accessor: 'remainInTotalSr',
        Cell: ({ value }) => value || 0,
      },
      { Header: 'appGroupId', accessor: 'appGroupId' },
    ],
    [],
  );
  const data = useMemo(() => {
    return pipe(
      () => tableData.tickets,
      groupBy(({ id, ticketType }) => `${id}-${ticketType}`),
      values,
      map((groupedTickets) => {
        const ticketsIn = sum(
          map(prop<'ticketsIn', any>('ticketsIn'), groupedTickets),
        );

        const ticketsOut = sum(
          map(prop<'ticketsOut', any>('ticketsOut'), groupedTickets),
        );

        const remainInTotal = sum(
          map(prop<'remainInTotal', any>('remainInTotal'), groupedTickets),
        );

        const supportLevel = join(
          '+',
          uniqBy(eqBy(String), map(prop('supportLevel'), groupedTickets)),
        );

        const [ticket] = groupedTickets;

        return {
          ...ticket,
          ticketsIn,
          ticketsOut,
          remainInTotal,
          supportLevel,
        };
      }),
      groupBy(({ id }) => id),
      values,
      /**
       * This step in sequence as input has grouped tickets, and as output need to have one combined ticket
       */
      map((groupedTickets) => {
        // We rename properties to match names related to ticketType
        const transformedTicketProperties = groupedTickets.map((ticket) => ({
          ...ticket,
          [createKeyByTicketType('ticketsOut', ticket.ticketType)]:
            ticket.ticketsOut,
          [createKeyByTicketType('ticketsIn', ticket.ticketType)]:
            ticket.ticketsIn,
          [createKeyByTicketType('remainInTotal', ticket.ticketType)]:
            ticket.remainInTotal,
        }));

        /**
         * As all ticket have same properties and their values except those we changed above
         * We will have at current moment only ticket for SR and Inc so we combine only two tickets into one
         */
        return Object.assign({}, ...transformedTicketProperties);
      }),
      map((ticket: TicketManagementAppData) => ({
        ...ticket,
        appName: ticket.name,
        appGroupId: ticket.applicationGroupId,
      })),
    )();
  }, [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          groupBy: ['appGroupId'],
          hiddenColumns: ['appGroupId'],
        } as any,
      },
      useGroupBy,
    );

  const renderTableHead = () => {
    return (
      <Thead backgroundColor="#414042" position="sticky" top={0} zIndex={1}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps()}
                textTransform="none"
                color="#ffffff"
                fontSize="s"
                fontWeight="normal"
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
    );
  };

  const renderTableBody = () => {
    const renderGroupingRow = (row: Row) => {
      const groupColor = '#808285';
      const { values, cells } = row;
      const [headerGroupName] = cells;

      const group = Object.values(tableData.applicationGroups).find(
        ({ id }) => id === values.appGroupId,
      );
      const headerGroupProps = headerGroupName.getCellProps();
      const rowProps = row.getRowProps();

      const colspanValue = columns.length - 1;

      if (!group) {
        return null;
      }

      return (
        <Tr
          {...rowProps}
          key={rowProps.key}
          padding={0}
          boxShadow="lg"
          position="relative"
          color={groupColor}
          bg="#e6e7e8"
        >
          <Td
            {...headerGroupProps}
            position="sticky"
            left={0}
            paddingTop={1}
            paddingBottom={1}
            paddingLeft={6}
            color={groupColor}
            colSpan={colspanValue}
            fontSize="s"
            borderBottom="none"
          >
            {group.name}
          </Td>
        </Tr>
      );
    };

    const renderBodyRows = (row: any) => {
      return row.leafRows.map((leafRow: any) => {
        prepareRow(leafRow);

        return (
          <Tr {...leafRow.getRowProps()}>
            {leafRow.cells.map((cell: any) => {
              return (
                <Td
                  {...cell.getCellProps()}
                  fontSize="s"
                  color="#414042"
                  paddingTop={3}
                  paddingBottom={3}
                  paddingLeft={6}
                >
                  {cell.render('Cell')}
                </Td>
              );
            })}
          </Tr>
        );
      });
    };

    return (
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Fragment key={row.getRowProps().key}>
              {renderGroupingRow(row)}
              {renderBodyRows(row)}
            </Fragment>
          );
        })}
      </Tbody>
    );
  };

  return (
    <Stack flex={1} width="full" {...containerProps}>
      <Table {...getTableProps()}>
        {renderTableHead()}
        {renderTableBody()}
      </Table>
    </Stack>
  );
};
