import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { eqBy, join, map, prop, sum, uniqBy } from 'ramda';
import { FC, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { toggleElement } from '../../utils/arrayHelpers';
import { AppData } from '../applicationMonitorPage/useApplicationsQuery';
import {
  TicketSupportLevel,
  ticketSupportLevels,
  TicketType,
  ticketTypes,
} from './ticketManagementDomain';
import { useTicketManagementChartsQuery } from './useTicketManagementChartsQuery';

const ticketTypeAbbrevation: Record<TicketType, string> = {
  INCIDENT: 'Inc',
  SERVICE_REQUEST: 'SR',
};

const getTicketTypeAbbrevation = (ticketType: TicketType) =>
  ticketTypeAbbrevation[ticketType];

export type TimeSpentChartProps = {
  applications: AppData[];
  periodValue: string;
  supportLevel: TicketSupportLevel | null;
};

// @ts-ignore
var isFirefox = typeof InstallTrigger !== 'undefined';

export const TimeSpentChart: FC<TimeSpentChartProps> = (props) => {
  const { periodValue, applications, supportLevel } = props;

  const [selectedApplicationIds, setSelectedApplicationIds] = useState<
    string[]
  >([]);

  const timeSpentChartQuery = useTicketManagementChartsQuery({
    startPeriodValue: periodValue,
    supportLevel,
  });

  const [chartContainerRef, chartContainerDimens] =
    useMeasure<HTMLDivElement>();

  const { entries, periods } = timeSpentChartQuery.data ?? {
    entries: [],
    periods: [],
  };
  const periodsByTime = Array.from(periods).sort((a, b) => {
    return (isFirefox ? -1 : 1) * Number(a.dateTime > b.dateTime);
  });
  const mostRecentPeriod = periodsByTime[0];
  const mostRecentPeriodEntries = entries.filter(
    ({ periodId }) => periodId === mostRecentPeriod.id,
  );

  const applicationsWithAnyTimeSpentData = applications.filter(({ id }) =>
    mostRecentPeriodEntries.some(({ applicationId }) => applicationId === id),
  );

  const chartData = useMemo(() => {
    return applicationsWithAnyTimeSpentData.flatMap(({ id, name, color }) => {
      return ticketTypes.map((type) => {
        const appEntriesPerSupportLevel = ticketSupportLevels
          .filter((level) => level === supportLevel || supportLevel === null)
          .map(
            (level) =>
              mostRecentPeriodEntries.find(
                ({ applicationId, supportLevel, ticketType }) =>
                  applicationId === id &&
                  supportLevel === level &&
                  ticketType === type,
              ) ?? {
                applicationId: id,
                numberOfTickets: 0,
                periodId: mostRecentPeriod.id,
                ticketType: type,
                remainInTotalLessThanMonth: 0,
                remainInTotalMoreThanThreeMonths: 0,
                remainInTotalOneToTwoMonths: 0,
                remainInTotalTwoToThreeMonths: 0,
                supportLevel: level,
              },
          );

        const remainInTotalLessThanMonth = sum(
          map(
            prop<'remainInTotalLessThanMonth', any>(
              'remainInTotalLessThanMonth',
            ),
            appEntriesPerSupportLevel,
          ),
        );

        const remainInTotalMoreThanThreeMonths = sum(
          map(
            prop<'remainInTotalMoreThanThreeMonths', any>(
              'remainInTotalMoreThanThreeMonths',
            ),
            appEntriesPerSupportLevel,
          ),
        );

        const remainInTotalOneToTwoMonths = sum(
          map(
            prop<'remainInTotalOneToTwoMonths', any>(
              'remainInTotalOneToTwoMonths',
            ),
            appEntriesPerSupportLevel,
          ),
        );

        const remainInTotalTwoToThreeMonths = sum(
          map(
            prop<'remainInTotalTwoToThreeMonths', any>(
              'remainInTotalTwoToThreeMonths',
            ),
            appEntriesPerSupportLevel,
          ),
        );

        const level = join(
          '+',
          uniqBy(
            eqBy(String),
            map(prop('supportLevel'), appEntriesPerSupportLevel),
          ),
        );

        const valueToLabelMap = {
          '< 1 month': remainInTotalLessThanMonth,
          '1 < 2 months': remainInTotalOneToTwoMonths,
          '2 < 3 months': remainInTotalTwoToThreeMonths,
          '> 3 months': remainInTotalMoreThanThreeMonths,
        };

        const scatterData = Object.entries(valueToLabelMap).map(
          ([label, value]) => ({
            appId: id,
            supportLevel: level,
            ticketType: type,
            color,
            name,
            x: label,
            y: value,
            z: value * 10 + 50,
          }),
        );

        return scatterData;
      });
    });
  }, [
    applicationsWithAnyTimeSpentData,
    mostRecentPeriodEntries,
    mostRecentPeriod?.id,
    supportLevel,
  ]);

  if (timeSpentChartQuery.isError) {
    return (
      <Center>
        <Alert>
          <HStack>
            <AlertIcon />
            <Text>
              Error:
              {(timeSpentChartQuery.error as Error).message ??
                'Error occurred. Please, refresh the page'}
            </Text>
          </HStack>
        </Alert>
      </Center>
    );
  }

  if (timeSpentChartQuery.isLoading) {
    return (
      <Center height="full" width="full">
        <Spinner />
      </Center>
    );
  }

  return (
    <Stack height="full">
      <Stack marginTop={10} border="1px solid #e6e7e8" padding={0} flex={1}>
        <Flex backgroundColor="#e6e7e8" width="full" paddingX={4} paddingY={3}>
          <Text color="#414042">Time spent in Backlog</Text>
        </Flex>

        <Box ref={chartContainerRef} flex={1} width="full">
          {/* TODO: rework this (warning in the console) */}
          <ResponsiveContainer
            width={chartContainerDimens.width}
            height={chartContainerDimens.height}
          >
            <ScatterChart margin={{ top: 20, right: 20, bottom: 10, left: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <Legend
                verticalAlign="bottom"
                align="right"
                layout="horizontal"
                iconSize={20}
                wrapperStyle={{ right: '0', cursor: 'pointer' }}
                content={(props: any) => {
                  const { payload } = props;
                  return (
                    <Grid templateColumns="repeat(4, 1fr)" gap={1}>
                      {uniqBy<any, any>(
                        ({ dataKey }) => dataKey.split('-')[0],
                        payload,
                      ).map((line: any) => {
                        const { color, value, inactive, payload } = line;
                        const { appId, ticketType } = payload?.data?.[0];

                        return (
                          <GridItem>
                            <Checkbox
                              sx={{
                                span: {
                                  fontSize: 'sm',
                                },
                              }}
                              whiteSpace="nowrap"
                              key={`${appId} (${getTicketTypeAbbrevation(
                                ticketType,
                              )})`}
                              isChecked={!inactive}
                              color={inactive ? 'gray' : color}
                              colorScheme="red"
                              onChange={() => {
                                if (
                                  selectedApplicationIds.length === 1 &&
                                  selectedApplicationIds[0] === appId
                                ) {
                                  return;
                                }

                                setSelectedApplicationIds((oldIds) =>
                                  toggleElement(oldIds, appId),
                                );
                              }}
                            >
                              {value}
                            </Checkbox>
                          </GridItem>
                        );
                      })}
                    </Grid>
                  );
                }}
              />

              {chartData.map((scatterData) => {
                const {
                  appId: id,
                  supportLevel,
                  ticketType,
                  name,
                  color,
                } = scatterData[0];

                return (
                  <Scatter
                    dataKey={`${id}-${supportLevel}-${ticketType}`}
                    key={`${id}-${supportLevel}-${ticketType}`}
                    name={`${name}`}
                    data={scatterData}
                    fill={color}
                    opacity={0.8}
                    hide={
                      !selectedApplicationIds.some((appId) => {
                        return appId.startsWith(id);
                      })
                    }
                  />
                );
              })}

              <XAxis
                dataKey="x"
                name="period"
                allowDuplicatedCategory={false}
                fontSize={12}
                interval={0}
              />
              <YAxis
                dataKey="y"
                scale="linear"
                name="tickets"
                fontSize={12}
                interval={0}
                domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]}
              />
              <ZAxis dataKey="z" range={[200, 2000]} scale="pow" />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                content={(props: any) => {
                  const { payload } = props?.payload[0] ?? {};
                  if (!payload) {
                    return null;
                  }
                  const {
                    y: ticketCount,
                    appId,
                    x: periodName,
                    ticketType,
                  } = payload;

                  const { name: appName, color } = applications.find(
                    ({ id }) => id === appId,
                  )!;

                  return (
                    <Stack
                      background="white"
                      padding={3}
                      spacing={2}
                      border="1px solid"
                      borderColor="gray.300"
                    >
                      <Text color={color} fontSize="lg">
                        {`${appName} (${getTicketTypeAbbrevation(ticketType)})`}
                      </Text>
                      <Text color="gray">{periodName}</Text>
                      <Text>
                        {ticketCount}
                        &nbsp;
                        {ticketCount === 1 ? 'ticket' : 'tickets'}
                      </Text>
                    </Stack>
                  );
                }}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Stack>
  );
};
