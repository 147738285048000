import { Center, Heading, Spinner, Stack, Text } from '@chakra-ui/react';
import { FormControl, FormLabel, Select, SelectProps } from '@dpd-ui/core';
import { useEffect, useState } from 'react';
import { AppPage } from '../../ui-components/AppPage';
import { useApplicationsGrafanaDashboardsQuery } from './useApplicationsGrafanaDashboardsQuery';
import { useGrafanaTokenQuery } from './useGrafanaTokenQuery';

export const GrafanaDashboard = () => {
  const {
    data: tokenData,
    error: tokenError,
    isLoading: tokenIsLoading,
    isRefetching: tokenIsRefetching,
    refetch: tokenRefetch,
  } = useGrafanaTokenQuery();
  const {
    data: applicationsData,
    error: applicationsError,
    isLoading: applicationsIsLoading,
  } = useApplicationsGrafanaDashboardsQuery();

  const [iframeSrc, setIframeSrc] = useState<string>();
  const [selectedApplicationId, setSelectedApplicationId] = useState<
    string | null
  >();
  const [tokenRefreshDelay, setTokenRefreshDelay] = useState<number>(60000);

  useEffect(() => {
    if (
      tokenIsLoading ||
      tokenIsRefetching ||
      !selectedApplicationId ||
      applicationsIsLoading ||
      !applicationsData ||
      !tokenData
    ) {
      return;
    }

    const applicationsDataMap = new Map(
      applicationsData.map((obj) => [obj.id, obj.dashboardId]),
    );

    setIframeSrc(
      `${process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080'}/public-dashboards/${applicationsDataMap.get(selectedApplicationId)}?applicationId=${selectedApplicationId}&token=${tokenData}`,
    );
  }, [
    tokenData,
    tokenIsLoading,
    tokenIsRefetching,
    selectedApplicationId,
    applicationsData,
  ]);

  useEffect(() => {
    if (tokenData) {
      const tokenDecoded = atob(tokenData);
      const abc: number = parseInt(tokenDecoded.slice(0, 13));
      console.log(abc - Date.now());
      setTokenRefreshDelay(abc - Date.now());
    }
  }, [tokenData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      tokenRefetch();
    }, tokenRefreshDelay);

    return () => clearTimeout(timeoutId);
  }, [tokenRefetch, tokenRefreshDelay]);

  // useEffect(() => {
  //   tokenRefetch();
  // }, [selectedApplicationId, tokenRefetch]);

  if (tokenIsLoading || applicationsIsLoading) {
    return (
      <AppPage>
        <Center height="full" width="full">
          <Spinner />
        </Center>
      </AppPage>
    );
  }

  if (tokenError || applicationsError) {
    return (
      <AppPage>
        <Center height="full" width="full">
          <Text>Error fetching data</Text>
        </Center>
      </AppPage>
    );
  }

  applicationsData.sort(({ name: a }, { name: b }) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  });

  const options: SelectProps['options'] = applicationsData.map(
    ({ name, id }) => ({
      value: id,
      label: name,
    }),
  );

  const onApplicationChange = (value: string | null) => {
    if (!value) {
      return;
    }

    setSelectedApplicationId(value);
  };

  return (
    <AppPage>
      <Stack flex={1} padding={5} spacing={8} width={0} height="full">
        <Heading
          fontWeight="light"
          color="rgba(65, 64, 66, 1)"
          fontSize="1.325rem"
        >
          KPI Dashboards
        </Heading>
        <FormControl width="450px">
          <Select
            onChange={onApplicationChange}
            options={options}
            value={selectedApplicationId}
          />
          <FormLabel>Choose application</FormLabel>
        </FormControl>
        {iframeSrc && (
          <iframe
            title="Dashboard"
            src={iframeSrc}
            style={{
              border: 'none',
              height: '100vh',
            }}
          />
        )}
      </Stack>
    </AppPage>
  );
};
