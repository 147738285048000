import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';
import { Id } from '../applicationMonitorPage/types';

export const createApplicationsGrafanaDashboardsCacheKey = () => [
  'applicationsGrafanaDashboards',
];

export type ApplicationData = {
  id: Id;
  name: string;
  dashboardId: string;
};

type ApplicationsData = ApplicationData[];

type QueryResult = ApiResponse<ApplicationsData>;

export const useApplicationsGrafanaDashboardsQuery = (
  opts?: UseQueryOptions<QueryResult, AxiosError>,
  axios?: AxiosRequestConfig,
) => {
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<QueryResult>(`/grafana/applications`, {
      ...axios,
      signal,
    });
    return data.map(({ id, name, dashboardId }) => ({
      id,
      name,
      dashboardId,
    }));
  };

  const query = useQuery<QueryResult, AxiosError>(
    createApplicationsGrafanaDashboardsCacheKey(),
    queryFn,
    opts,
  );

  return query;
};
