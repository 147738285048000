import { useEffect } from 'react';
import { decode } from '../../utils/jwtHelpers';
import { getTimeInSeconds } from '../../utils/timeHelpers';
import { JWT } from './types';

interface useAutoLogoutProps {
  refreshToken: string;

  signOut(): Promise<void>;
}

export const useAutoLogout = ({ signOut, refreshToken }: useAutoLogoutProps) =>
  useEffect(() => {
    if (!refreshToken) return;
    const { exp } = decode<JWT>(refreshToken);
    const logoutAt = exp - getTimeInSeconds();
    if (logoutAt < 0) return;

    const autoLogout = setTimeout(() => {
      signOut();
    }, logoutAt * 1000);

    return () => {
      clearTimeout(autoLogout);
    };
  }, [signOut, refreshToken]);
