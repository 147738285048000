import { context } from 'msw';

export const response = () => {
  return context.json([
    {
      applicationId: '1',
      id: '1',
      name: 'Metric A',
      status: 'OK',
      value: Math.random() * 100,
      updatedAt: Date.now(),
    },
    {
      applicationId: '1',
      id: '2',
      name: 'Metric B',
      status: 'EXCEEDED',
      value: Math.random() * 100,
      updatedAt: Date.now(),
    },
    {
      applicationId: '1',
      id: '3',
      name: 'Metric C',
      status: 'BREACHED',
      value: Math.random() * 100,
      updatedAt: Date.now(),
    },
    {
      applicationId: '1',
      id: '4',
      name: 'Metric D',
      status: 'OK',
      value: Math.random() * 100,
      updatedAt: Date.now(),
    },
    {
      applicationId: '1',
      id: '5',
      name: 'Metric E',
      status: 'OK',
      value: Math.random() * 100,
      updatedAt: Date.now(),
    },
    {
      applicationId: '1',
      id: '6',
      name: 'Metric F',
      status: 'EXCEEDED',
      value: Math.random() * 100,
      updatedAt: Date.now(),
    },
  ]);
};
