import { FC } from 'react';
import {
  Box,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { BsExclamationLg } from 'react-icons/bs';
import { Column, useGroupBy, useTable } from 'react-table';
import { ErrorIcon } from '../../icons/ErrorIcon';
import { InfoIcon } from '../../icons/InfoIcon';
import { LiveStatusDownIcon } from '../../icons/LiveStatusDownIcon';
import { LiveStatusUpIcon } from '../../icons/LiveStatusUpIcon';
import { SuccessIcon } from '../../icons/SuccessIcon';
import { UnknownIcon } from '../../icons/UnknownIcon';
import { convertMsToHM } from '../../utils/timeHelpers';
import { DashboardStatus } from '../applicationMonitorPage/types';
import { Entry } from './types';
import { calculateSlaColor } from '../../utils/slaHelpers';

const StatusIcon: FC<{ dashboardStatus: DashboardStatus }> = ({
  dashboardStatus,
}) => {
  const { status, newsletterSent } = dashboardStatus;
  return (
    <Box position="relative">
      {status === 'OK' && <SuccessIcon boxSize={5} fill="green" />}
      {status === 'OK_WITH_INFORMATION' && (
        <Box position="relative">
          <SuccessIcon boxSize={5} fill="green" />
          <Box
            as="span"
            position="absolute"
            top={0}
            right={0}
            transform="auto"
            translateX="90%"
            translateY="-30%"
          >
            <BsExclamationLg size={12} />
          </Box>
        </Box>
      )}
      {status === 'OUTAGE' && <ErrorIcon boxSize={5} fill="#dc0032" />}
      {status === 'DISRUPTION' && <InfoIcon boxSize={5} fill="#ffc004" />}
      {(!status || status === 'UNKNOWN') && (
        <UnknownIcon fill="#808285" boxSize={5} />
      )}
      {status && status !== 'UNKNOWN' && newsletterSent && (
        <Box
          as="span"
          position="absolute"
          top={0}
          right={0}
          transform="auto"
          translateX="100%"
          translateY="+50%"
        >
          <Text fontSize="xs">N</Text>
        </Box>
      )}
    </Box>
  );
};

const columns: Column<Entry>[] = [
  {
    Header: 'Live status',
    accessor: 'liveStatus',
    Cell: ({ value }) => {
      const isOkStatus = ['OK', 'OK_WITH_INFORMATION'].includes(value);

      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="20px"
          margin="5px 0"
          width="54px"
          borderRadius="5px"
          marginInline="10px"
          backgroundColor={
            isOkStatus ? 'rgba(80, 158, 47, 0.15)' : 'rgba(220, 0, 50, 0.15)'
          }
        >
          {isOkStatus ? (
            <LiveStatusUpIcon boxSize="24px" />
          ) : (
            <LiveStatusDownIcon boxSize="24px" />
          )}
        </Box>
      );
    },
  },
  {
    Header: 'Application',
    accessor: 'applicationName',
  },
  {
    Header: 'Last 24h uptime',
    accessor: 'recentUptime',
    Cell: ({ value }) => (
      <Text color={calculateSlaColor(value)}>{value} %</Text>
    ),
  },
  {
    Header: 'Last 24h downtime',
    accessor: 'recentDowntimeMillis',
    Cell: ({ value }) => (
      <Text>{value === 0 ? '0s' : convertMsToHM(value)}</Text>
    ),
  },
  {
    Header: ({ data }) => {
      const pastDates = Object.keys(data[0].pastStatuses);
      return (
        <HStack justifyContent="space-between">
          {pastDates.map((date) => (
            <span>{format(new Date(date), 'dd.MM')}</span>
          ))}
        </HStack>
      );
    },
    accessor: 'pastStatuses',
    Cell: ({ value }) => {
      const statuses = Object.values(value);
      return (
        <HStack justifyContent="space-between" alignItems="center">
          {statuses.sort().map((status) => (
            <StatusIcon dashboardStatus={status} />
          ))}
        </HStack>
      );
    },
  },
];

interface ApplicationMonitorTableProps {
  data: Entry[];
}

export const ApplicationMonitorTable: FC<ApplicationMonitorTableProps> = ({
  data,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useGroupBy,
    );

  return (
    <Table as="table" {...getTableProps()} width="full">
      <Thead as="thead">
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()} as="tr">
            {headerGroup.headers.map((column) => (
              <Th
                {...column.getHeaderProps()}
                as="th"
                textTransform="none"
                color="gray.500"
                fontSize="xs"
                fontWeight="normal"
                textAlign="left"
                borderBottomWidth={0}
                paddingLeft={0}
                paddingRight={4}
                paddingBottom="10px"
                paddingTop={0}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody as="tbody" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <Tr
              as="tr"
              {...row.getRowProps()}
              backgroundColor={'white'}
              border="1px solid #E6E7E7"
            >
              {row.cells.map((cell) => (
                <Td
                  {...cell.getCellProps()}
                  as="td"
                  fontSize="medium"
                  borderBottomWidth={0}
                  padding={0}
                  paddingRight={4}
                >
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
