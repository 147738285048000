import { context } from 'msw';
import { randomNumber } from '../../utils/mockHelpers';
import { appGroupNames, appNames } from '../data';

const DISABLE_APPLICATIONS_GROUPS = true;

export const response = () => {
  const response = {
    applicationGroups: appGroupNames.map((name, index) => {
      return {
        id: String(index),
        name,
      };
    }),
    tickets: appNames.map((appName, index) => ({
      name: appName,
      id: String(index),
      applicationGroupId: DISABLE_APPLICATIONS_GROUPS
        ? null
        : String(index % appGroupNames.length),
      blocker: randomNumber(10),
      critical: randomNumber(5),
      blockerThirdParty: randomNumber(5),
      criticalThirdParty: randomNumber(2),
      total: randomNumber(22),
    })),
  };

  return context.json(response);
};
