import { createIcon } from '@chakra-ui/react';

export const RingIcon = createIcon({
  displayName: 'RingIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      fill="currentColor"
      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
    />,
  ],
});
