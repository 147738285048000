import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useApi } from '../../api/ApiProvider';
import { ApiResponse } from '../../api/types';

export const createGetTokenCacheKey = () => ['getGrafanaToken'];

type QueryResult = ApiResponse<string>;

export const useGrafanaTokenQuery = (
  opts?: UseQueryOptions<QueryResult, AxiosError>,
) => {
  const api = useApi();

  const queryFn = async ({ signal }: { signal?: AbortSignal }) => {
    const { data } = await api.get<string>('/grafana/public-dashboards/token', {
      signal,
    });
    return data;
  };

  const query = useQuery<QueryResult, AxiosError>(
    createGetTokenCacheKey(),
    queryFn,
    opts,
  );

  return query;
};
