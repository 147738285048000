import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import invariant from 'tiny-invariant';

import { useEnchancedApi } from './useEnchancedApi';
import { useAutoLogout } from './useAutoLogout';
import { useUserScope } from './useUserScope';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { LoginPage } from '../../pages/LoginPage';
import { Credentials } from './types';

type Token = string;

interface SignInMutationQueryResult {
  accessToken: Token;
  refreshToken: Token;
}

interface RefreshTokenResponse extends SignInMutationQueryResult {}

type AuthContextState =
  | { state: 'loading' }
  | { state: 'signedOut'; signIn?: () => void }
  | { state: 'signedIn'; scopes: string[]; signOut?: () => void };

type AuthState = AuthContextState['state'];

const defaultValue: AuthContextState = {
  state: 'signedOut',
};

const AuthContext = createContext<AuthContextState>(defaultValue);

interface AuthCredentialsProviderProps {}

export const AuthCredentialsProvider: FC<
  React.PropsWithChildren<AuthCredentialsProviderProps>
> = ({ children }) => {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useLocalStorage<Token>('access-token');
  const [refreshToken, setRefreshToken] =
    useLocalStorage<Token>('refresh-token');

  const [currentAuthState, setCurrentAuthState] =
    useState<AuthState>('loading');

  const scopes = useUserScope({ token: accessToken! });

  const api = useEnchancedApi({
    onRefreshToken: async (api) => {
      const { data: regenerated } = await api.post<RefreshTokenResponse>(
        '/token/refresh',
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
          options: {
            tokenRefreshing: false,
          },
        } as any,
      );

      setAccessToken(regenerated.accessToken);
      setRefreshToken(regenerated.refreshToken);
    },
    token: accessToken!,
  });

  useEffect(() => {
    const signIn = async () => {
      try {
        const { data: regeneratedTokens } =
          await api.post<RefreshTokenResponse>(
            '/token/refresh',
            {},
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            },
          );

        setAccessToken(regeneratedTokens.accessToken);
        setRefreshToken(regeneratedTokens.refreshToken);

        setCurrentAuthState('signedIn');

        const referrer = sessionStorage.getItem('referrer');
        navigate(referrer!);
        sessionStorage.removeItem('referrer');
      } catch (_e: any) {
        setAccessToken('');
        setRefreshToken('');
        setCurrentAuthState('signedOut');
        navigate('/login', { replace: true });
      }
    };

    if (refreshToken && ['signedOut', 'loading'].includes(currentAuthState)) {
      signIn();
      return;
    }
  }, [
    accessToken,
    api,
    currentAuthState,
    navigate,
    refreshToken,
    setAccessToken,
    setRefreshToken,
  ]);

  useEffect(() => {
    let referrer = sessionStorage.getItem('referrer');
    if (referrer) return;

    const { pathname, search } = window.location;
    sessionStorage.setItem(
      'referrer',
      pathname !== '/login' ? `${pathname}${search}` : '/',
    );
  }, []);

  const signIn = useCallback(
    async (credentials: Credentials) => {
      try {
        const {
          data: { accessToken: appAccessToken, refreshToken: appRefreshToken },
        } = await api.post('/token', credentials);

        setAccessToken(appAccessToken);
        setRefreshToken(appRefreshToken);
        setCurrentAuthState('signedIn');

        const referrer = sessionStorage.getItem('referrer');
        navigate(referrer ?? '/');
        sessionStorage.removeItem('referrer');
      } catch (e) {
        setCurrentAuthState('signedOut');
      }
    },
    [api, setAccessToken, setRefreshToken, navigate],
  );

  const signOut = useCallback(async () => {
    try {
      await api.post(
        '/token/logout',
        {
          accessToken,
          refreshToken,
        },
        {
          options: {
            tokenRefreshing: false,
          },
        } as any,
      );
    } finally {
      setAccessToken('');
      setRefreshToken('');
      setCurrentAuthState('signedOut');
      navigate('/login', { replace: true });
    }
  }, [
    accessToken,
    api,
    navigate,
    refreshToken,
    setAccessToken,
    setRefreshToken,
  ]);

  useAutoLogout({
    signOut,
    refreshToken: refreshToken!,
  });

  const authState: AuthContextState = useMemo(() => {
    switch (currentAuthState) {
      case 'loading': {
        return {
          state: 'loading',
        };
      }
      case 'signedOut': {
        return {
          state: 'signedOut',
          signIn: () => {},
        };
      }

      case 'signedIn': {
        return { state: 'signedIn', signOut, scopes };
      }
    }
  }, [currentAuthState, scopes, signOut]);

  if (!accessToken && !refreshToken) {
    return <LoginPage onSignIn={signIn} />;
  }

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

export const useAuthCredentials = () => {
  const auth = useContext(AuthContext);

  invariant(
    auth !== defaultValue,
    `"useAuthCredentials" hook used outside of its' provider`,
  );

  return auth;
};
